import React from "react";

const CheckedIcon = () => {
  return (
    <svg
      id="Notification_Selection_-_Checkbox"
      data-name="Notification Selection - Checkbox"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_96"
        data-name="Rectangle 96"
        width="24"
        height="24"
        rx="6"
        fill="#78c2a7"
      />
      <path
        id="check"
        d="M15.29,6,7.528,13.78,4,10.244"
        transform="translate(2.5 2.11)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </svg>
  );
};

export default CheckedIcon;
