import React from "react";

const DefaultCheckBoxIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Rectangle_96"
        data-name="Rectangle 96"
        fill="#f5f5f5"
        stroke="#003764"
        stroke-width="1"
      >
        <rect width="24" height="24" rx="6" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="none" />
      </g>
    </svg>
  );
};

export default DefaultCheckBoxIcon;
