import React from "react";

const InterMediateCheckedIcon = () => {
  return (
    <svg
      id="Notification_Selection_-_Checkbox"
      data-name="Notification Selection - Checkbox"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_96"
        data-name="Rectangle 96"
        width="24"
        height="24"
        rx="6"
        fill="#e2662c"
      />
      <path
        id="Path_12106"
        data-name="Path 12106"
        d="M11.448,9.58m0,0,0,0H18.9m-7.448,0H4"
        transform="translate(0.552 2.42)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </svg>
  );
};

export default InterMediateCheckedIcon;
