// Header array for NRL Ladder Table
export const NRLLadderHeader = [
  {
    key: "team",
    value: "Team",
  },
  {
    key: "points",
    value: "Pts",
  },
  {
    key: "gamesPlayed",
    value: "GP",
  },
  {
    key: "wins",
    value: "W",
  },
  {
    key: "losses",
    value: "L",
  },
  {
    key: "draws",
    value: "D",
  },
  {
    key: "byes",
    value: "Bye",
  },
  {
    key: "pointsFor",
    value: "PF",
  },
  {
    key: "pointsAgainst",
    value: "PA",
  },
  {
    key: "pointsDiff",
    value: "PD",
  },
  {
    key: "home",
    value: "H",
  },
  {
    key: "away",
    value: "A",
  },
  {
    key: "form",
    value: "F",
  },
];

// Body array for NRL Ladder Table
export const NRLLadderBody = [
  {
    position: 1,
    team: "[Team_name]",
    points: 16,
    gamesPlayed: 16,
    wins: 16,
    losses: 16,
    draws: 16,
    byes: 16,
    pointsFor: 16,
    pointsAgainst: 16,
    pointsDiff: "9-3",
    home: "9-3",
    away: "9-3",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 2,
    team: "[Team_name]",
    points: 14,
    gamesPlayed: 14,
    wins: 14,
    losses: 14,
    draws: 14,
    byes: 14,
    pointsFor: 14,
    pointsAgainst: 14,
    pointsDiff: "10-2",
    home: "10-2",
    away: "10-2",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 3,
    team: "[Team_name]",
    points: 13,
    gamesPlayed: 13,
    wins: 13,
    losses: 13,
    draws: 13,
    byes: 13,
    pointsFor: 13,
    pointsAgainst: 13,
    pointsDiff: "8-4",
    home: "8-4",
    away: "8-4",
    form: ["B", "W", "L", "L", "W"],
  },
  {
    position: 4,
    team: "[Team_name]",
    points: 11,
    gamesPlayed: 11,
    wins: 11,
    losses: 11,
    draws: 11,
    byes: 11,
    pointsFor: 11,
    pointsAgainst: 11,
    pointsDiff: "9-3",
    home: "9-3",
    away: "9-3",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 5,
    team: "[Team_name]",
    points: 8,
    gamesPlayed: 8,
    wins: 8,
    losses: 8,
    draws: 8,
    byes: 8,
    pointsFor: 8,
    pointsAgainst: 8,
    pointsDiff: "10-2",
    home: "10-2",
    away: "10-2",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 6,
    team: "[Team_name]",
    points: 8,
    gamesPlayed: 8,
    wins: 8,
    losses: 8,
    draws: 8,
    byes: 8,
    pointsFor: 8,
    pointsAgainst: 8,
    pointsDiff: "8-4",
    home: "8-4",
    away: "8-4",
    form: ["B", "W", "L", "L", "W"],
  },
  {
    position: 7,
    team: "[Team_name]",
    points: 6,
    gamesPlayed: 6,
    wins: 6,
    losses: 6,
    draws: 6,
    byes: 6,
    pointsFor: 6,
    pointsAgainst: 6,
    pointsDiff: "9-3",
    home: "9-3",
    away: "9-3",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 8,
    team: "[Team_name]",
    points: 4,
    gamesPlayed: 4,
    wins: 4,
    losses: 4,
    draws: 4,
    byes: 4,
    pointsFor: 4,
    pointsAgainst: 4,
    pointsDiff: "10-2",
    home: "10-2",
    away: "10-2",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 9,
    team: "[Team_name]",
    points: 8,
    gamesPlayed: 8,
    wins: 8,
    losses: 8,
    draws: 8,
    byes: 8,
    pointsFor: 8,
    pointsAgainst: 8,
    pointsDiff: "8-4",
    home: "8-4",
    away: "8-4",
    form: ["B", "W", "L", "L", "W"],
  },
  {
    position: 10,
    team: "[Team_name]",
    points: 8,
    gamesPlayed: 8,
    wins: 8,
    losses: 8,
    draws: 8,
    byes: 8,
    pointsFor: 8,
    pointsAgainst: 8,
    pointsDiff: "9-3",
    home: "9-3",
    away: "9-3",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 11,
    team: "[Team_name]",
    points: 6,
    gamesPlayed: 6,
    wins: 6,
    losses: 6,
    draws: 6,
    byes: 6,
    pointsFor: 6,
    pointsAgainst: 6,
    pointsDiff: "10-2",
    home: "10-2",
    away: "10-2",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 12,
    team: "[Team_name]",
    points: 4,
    gamesPlayed: 4,
    wins: 4,
    losses: 4,
    draws: 4,
    byes: 4,
    pointsFor: 4,
    pointsAgainst: 4,
    pointsDiff: "8-4",
    home: "8-4",
    away: "8-4",
    form: ["B", "W", "L", "L", "W"],
  },
  {
    position: 13,
    team: "[Team_name]",
    points: 8,
    gamesPlayed: 8,
    wins: 8,
    losses: 8,
    draws: 8,
    byes: 8,
    pointsFor: 8,
    pointsAgainst: 8,
    pointsDiff: "9-3",
    home: "9-3",
    away: "9-3",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 14,
    team: "[Team_name]",
    points: 8,
    gamesPlayed: 8,
    wins: 8,
    losses: 8,
    draws: 8,
    byes: 8,
    pointsFor: 8,
    pointsAgainst: 8,
    pointsDiff: "10-2",
    home: "10-2",
    away: "10-2",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 15,
    team: "[Team_name]",
    points: 6,
    gamesPlayed: 6,
    wins: 6,
    losses: 6,
    draws: 6,
    byes: 6,
    pointsFor: 6,
    pointsAgainst: 6,
    pointsDiff: "8-4",
    home: "8-4",
    away: "8-4",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 16,
    team: "[Team_name]",
    points: 4,
    gamesPlayed: 4,
    wins: 4,
    losses: 4,
    draws: 4,
    byes: 4,
    pointsFor: 4,
    pointsAgainst: 4,
    pointsDiff: "9-3",
    home: "9-3",
    away: "9-3",
    form: ["W", "W", "L", "L", "W"],
  },
  {
    position: 17,
    team: "[Team_name]",
    points: 8,
    gamesPlayed: 8,
    wins: 8,
    losses: 8,
    draws: 8,
    byes: 8,
    pointsFor: 8,
    pointsAgainst: 8,
    pointsDiff: "10-2",
    home: "10-2",
    away: "10-2",
    form: ["W", "W", "L", "L", "W"],
  },
];

// Header array for NRLStats Table
export const NRLStatsHeader = [
  {
    key: "team",
    value: "Team",
  },
  {
    key: "points",
    value: "PTS",
  },
  {
    key: "tries",
    value: "T",
  },
  {
    key: "goals",
    value: "GLS",
  },
  {
    key: "fieldGoals",
    value: "FG",
  },
  {
    key: "penaltyKicks",
    value: "PK",
  },
  {
    key: "scrums",
    value: "SCK",
  },
  {
    key: "superiorPlay",
    value: "SUP",
  },
  {
    key: "lineBreaks",
    value: "LE",
  },
  {
    key: "lineBreakAssists",
    value: "LB",
  },
  {
    key: "postContactMeters",
    value: "PCM",
  },
  {
    key: "tryBreaks",
    value: "TB",
  },
  {
    key: "dummyRuns",
    value: "DR",
  },
  {
    key: "runMeters",
    value: "RM",
  },
  {
    key: "runs",
    value: "RUN",
  },
  {
    key: "kickReturnMeters",
    value: "KRM",
  },
  {
    key: "defendingHigherResult",
    value: "DHR",
  },
  {
    key: "tackles",
    value: "TAC",
  },
];

// Body array for NRLStats Table
export const NRLStatsBody = [
  {
    position: 1,
    team: "[Team_name]",
    points: 14,
    tries: 14,
    goals: 14,
    fieldGoals: 14,
    penaltyKicks: 14,
    scrums: 14,
    superiorPlay: 14,
    lineBreaks: 14,
    lineBreakAssists: 14,
    postContactMeters: 14,
    tryBreaks: 14,
    dummyRuns: 14,
    runMeters: 14,
    runs: 14,
    kickReturnMeters: 14,
    defendingHigherResult: 14,
    tackles: 14,
  },
  {
    position: 2,
    team: "[Team_name]",
    points: 13,
    tries: 13,
    goals: 13,
    fieldGoals: 13,
    penaltyKicks: 13,
    scrums: 13,
    superiorPlay: 13,
    lineBreaks: 13,
    lineBreakAssists: 13,
    postContactMeters: 13,
    tryBreaks: 13,
    dummyRuns: 13,
    runMeters: 13,
    runs: 13,
    kickReturnMeters: 13,
    defendingHigherResult: 13,
    tackles: 13,
  },
  {
    position: 3,
    team: "[Team_name]",
    points: 11,
    tries: 11,
    goals: 11,
    fieldGoals: 11,
    penaltyKicks: 11,
    scrums: 11,
    superiorPlay: 11,
    lineBreaks: 11,
    lineBreakAssists: 11,
    postContactMeters: 11,
    tryBreaks: 11,
    dummyRuns: 11,
    runMeters: 11,
    runs: 11,
    kickReturnMeters: 11,
    defendingHigherResult: 11,
    tackles: 11,
  },
  {
    position: 4,
    team: "[Team_name]",
    points: 8,
    tries: 8,
    goals: 8,
    fieldGoals: 8,
    penaltyKicks: 8,
    scrums: 8,
    superiorPlay: 8,
    lineBreaks: 8,
    lineBreakAssists: 8,
    postContactMeters: 8,
    tryBreaks: 8,
    dummyRuns: 8,
    runMeters: 8,
    runs: 8,
    kickReturnMeters: 8,
    defendingHigherResult: 8,
    tackles: 8,
  },
  {
    position: 5,
    team: "[Team_name]",
    points: 8,
    tries: 8,
    goals: 8,
    fieldGoals: 8,
    penaltyKicks: 8,
    scrums: 8,
    superiorPlay: 8,
    lineBreaks: 8,
    lineBreakAssists: 8,
    postContactMeters: 8,
    tryBreaks: 8,
    dummyRuns: 8,
    runMeters: 8,
    runs: 8,
    kickReturnMeters: 8,
    defendingHigherResult: 8,
    tackles: 8,
  },
  {
    position: 6,
    team: "[Team_name]",
    points: 6,
    tries: 6,
    goals: 6,
    fieldGoals: 6,
    penaltyKicks: 6,
    scrums: 6,
    superiorPlay: 6,
    lineBreaks: 6,
    lineBreakAssists: 6,
    postContactMeters: 6,
    tryBreaks: 6,
    dummyRuns: 6,
    runMeters: 6,
    runs: 6,
    kickReturnMeters: 6,
    defendingHigherResult: 6,
    tackles: 6,
  },
  {
    position: 7,
    team: "[Team_name]",
    points: 4,
    tries: 4,
    goals: 4,
    fieldGoals: 4,
    penaltyKicks: 4,
    scrums: 4,
    superiorPlay: 4,
    lineBreaks: 4,
    lineBreakAssists: 4,
    postContactMeters: 4,
    tryBreaks: 4,
    dummyRuns: 4,
    runMeters: 4,
    runs: 4,
    kickReturnMeters: 4,
    defendingHigherResult: 4,
    tackles: 4,
  },
  {
    position: 8,
    team: "[Team_name]",
    points: 8,
    tries: 8,
    goals: 8,
    fieldGoals: 8,
    penaltyKicks: 8,
    scrums: 8,
    superiorPlay: 8,
    lineBreaks: 8,
    lineBreakAssists: 8,
    postContactMeters: 8,
    tryBreaks: 8,
    dummyRuns: 8,
    runMeters: 8,
    runs: 8,
    kickReturnMeters: 8,
    defendingHigherResult: 8,
    tackles: 8,
  },
  {
    position: 9,
    team: "[Team_name]",
    points: 8,
    tries: 8,
    goals: 8,
    fieldGoals: 8,
    penaltyKicks: 8,
    scrums: 8,
    superiorPlay: 8,
    lineBreaks: 8,
    lineBreakAssists: 8,
    postContactMeters: 8,
    tryBreaks: 8,
    dummyRuns: 8,
    runMeters: 8,
    runs: 8,
    kickReturnMeters: 8,
    defendingHigherResult: 8,
    tackles: 8,
  },
  {
    position: 10,
    team: "[Team_name]",
    points: 6,
    tries: 6,
    goals: 6,
    fieldGoals: 6,
    penaltyKicks: 6,
    scrums: 6,
    superiorPlay: 6,
    lineBreaks: 6,
    lineBreakAssists: 6,
    postContactMeters: 6,
    tryBreaks: 6,
    dummyRuns: 6,
    runMeters: 6,
    runs: 6,
    kickReturnMeters: 6,
    defendingHigherResult: 6,
    tackles: 6,
  },
  {
    position: 11,
    team: "[Team_name]",
    points: 4,
    tries: 4,
    goals: 4,
    fieldGoals: 4,
    penaltyKicks: 4,
    scrums: 4,
    superiorPlay: 4,
    lineBreaks: 4,
    lineBreakAssists: 4,
    postContactMeters: 4,
    tryBreaks: 4,
    dummyRuns: 4,
    runMeters: 4,
    runs: 4,
    kickReturnMeters: 4,
    defendingHigherResult: 4,
    tackles: 4,
  },
  {
    position: 12,
    team: "[Team_name]",
    points: 4,
    tries: 4,
    goals: 4,
    fieldGoals: 4,
    penaltyKicks: 4,
    scrums: 4,
    superiorPlay: 4,
    lineBreaks: 4,
    lineBreakAssists: 4,
    postContactMeters: 4,
    tryBreaks: 4,
    dummyRuns: 4,
    runMeters: 4,
    runs: 4,
    kickReturnMeters: 4,
    defendingHigherResult: 4,
    tackles: 4,
  },
  {
    position: 13,
    team: "[Team_name]",
    points: 8,
    tries: 8,
    goals: 8,
    fieldGoals: 8,
    penaltyKicks: 8,
    scrums: 8,
    superiorPlay: 8,
    lineBreaks: 8,
    lineBreakAssists: 8,
    postContactMeters: 8,
    tryBreaks: 8,
    dummyRuns: 8,
    runMeters: 8,
    runs: 8,
    kickReturnMeters: 8,
    defendingHigherResult: 8,
    tackles: 8,
  },
  {
    position: 14,
    team: "[Team_name]",
    points: 4,
    tries: 4,
    goals: 4,
    fieldGoals: 4,
    penaltyKicks: 4,
    scrums: 4,
    superiorPlay: 4,
    lineBreaks: 4,
    lineBreakAssists: 4,
    postContactMeters: 4,
    tryBreaks: 4,
    dummyRuns: 4,
    runMeters: 4,
    runs: 4,
    kickReturnMeters: 4,
    defendingHigherResult: 4,
    tackles: 4,
  },
  {
    position: 15,
    team: "[Team_name]",
    points: 8,
    tries: 8,
    goals: 8,
    fieldGoals: 8,
    penaltyKicks: 8,
    scrums: 8,
    superiorPlay: 8,
    lineBreaks: 8,
    lineBreakAssists: 8,
    postContactMeters: 8,
    tryBreaks: 8,
    dummyRuns: 8,
    runMeters: 8,
    runs: 8,
    kickReturnMeters: 8,
    defendingHigherResult: 8,
    tackles: 8,
  },
  {
    position: 16,
    team: "[Team_name]",
    points: 8,
    tries: 8,
    goals: 8,
    fieldGoals: 8,
    penaltyKicks: 8,
    scrums: 8,
    superiorPlay: 8,
    lineBreaks: 8,
    lineBreakAssists: 8,
    postContactMeters: 8,
    tryBreaks: 8,
    dummyRuns: 8,
    runMeters: 8,
    runs: 8,
    kickReturnMeters: 8,
    defendingHigherResult: 8,
    tackles: 8,
  },
  {
    position: 17,
    team: "[Team_name]",
    points: 6,
    tries: 6,
    goals: 6,
    fieldGoals: 6,
    penaltyKicks: 6,
    scrums: 6,
    superiorPlay: 6,
    lineBreaks: 6,
    lineBreakAssists: 6,
    postContactMeters: 6,
    tryBreaks: 6,
    dummyRuns: 6,
    runMeters: 6,
    runs: 6,
    kickReturnMeters: 6,
    defendingHigherResult: 6,
    tackles: 6,
  },
];
