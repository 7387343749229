import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import bannerHeader from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import OurPartner from "../ourPartners";
import { useSelector } from "react-redux";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import { ReactComponent as Timepicker } from "src/assets/images/eventcalendar/Timepicker.svg";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../assets/images/icons/smartb-check.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/icons/info-icon.svg";
import { ReactComponent as Joker } from "../../../assets/images/icons/joker.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/icons/delete-icon.svg";
import compImage from "../../../assets/images/round-dummy-image.png";
import { ReactComponent as AddButton } from "../../../assets/images/round-add-icon.svg";
import AdBannner from "../AdBanner/AdBanner";
import { toast } from "react-toastify";
import _ from "lodash";
import { Formik } from "formik";
import Select, { components } from "react-select";
import * as Yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
// import { parseISO } from "date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
//   DatePicker,
//   KeyboardTimePicker,
//   TimePicker
// } from "@material-ui/pickers";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import CircularProgress from "@mui/material/CircularProgress";
import "./createComp.scss";
import moment from "moment-timezone";
import SocialShareModal from "../UI/socialShareModal";
import { release } from "src/helpers/context/release";
import UploadImageModal from "../UI/uploadImage";
import TippingSupportedTeamModal from "../UI/tippingSupportedTeamModal";
import PaymentModal from "../UI/paymentModal";
import {
  dayOptions,
  isTipsterShown,
  privacyOptions,
  tipDeadlineOptions,
  ifNoTipOptions,
  tippingTypeOption,
  tippingTypeRadio,
  isTipsterTipping,
  roundBonus,
  includeFinals,
  jokerBonus,
  marginSystem,
  entryFee,
  prizeInformation,
} from "./createCompUtils";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CreateCompPage = () => {
  const navigate = useNavigate();
  const formikRef = useRef();
  const limit = 20;
  const [teamApiCount, setTeamApiCount] = useState(0);
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState("");
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState("");
  const [OrgAll, setOrgAll] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [compName, setCompName] = useState("");
  const [compAbout, setCompAbout] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [compNameError, setCompNameError] = useState("");
  const [open, setOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [openSocialShareModal, setOpenSocialShareModal] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [formvalues, setformvalues] = useState({});
  const [dateSelected, setDateSelected] = useState("");
  const [selectedTime, handleTimeChange] = useState("");
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [countOrg, setcountOrg] = useState(0);
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [selectedTipsterShown, setSelectedTipsterShown] = useState(null);
  const [selectedTipsterTippingShown, setSelectedTipsterTippingShown] =
    useState(null);
  const [selectedTipsType, setSelectedTipsType] = useState("");
  const [roundBonusOption, setRoundBonusOption] = useState();
  const [customRoundBonusOption, setCustomRoundBonusOption] = useState();
  const [customRoundBonusPoint, setCustomRoundBonusPoint] = useState();
  const [includeFinalsOption, setIncludeFinalsOption] = useState();
  const [includeFinalsPoint, setIncludeFinalsPoint] = useState(1);
  const [jokerBonusOption, setJokerBonusOption] = useState();
  const [numberOfJokerRound, setNumberOfJokerRound] = useState();
  const [marginSystemOption, setMarginSystemOption] = useState();
  const [entryFeeOption, setEntryFeeOption] = useState();
  const [entryFeePoint, setEntryFeePoint] = useState();
  // const [prizeInformationOption, setPrizeInformationOption] = useState();
  const [prizeInformationText, setPrizeInformationText] = useState("");
  const [managePrizeInformation, setManagePrizeInformation] = useState();
  const [rounds, setRounds] = useState([]);
  const [remainingRoundsCount, setRemainingRoundsCount] = useState("");
  const [selectedRound, setSelectedRound] = useState("");
  const [selectedRoundStartDate, setSelectedRoundStartDate] = useState(null);
  const [selectedPrivacy, setSelectedPrivacy] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTipDeadline, setSelectedTipDeadline] = useState("");
  const [selectedNoTip, setSelectedNoTip] = useState("");
  const [shareCode, setShareCode] = useState("");
  const [noRoundData, setNoRoundData] = useState("");
  const [expireRoundError, setExpireRoundError] = useState("");
  const [expireStartTimeError, setExpireStartTimeError] = useState("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [openSupportedTeamModal, setOpenSupportedTeamModal] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [createdCompId, setCreatedCompId] = useState("");
  const [compFee, setCompFee] = useState("");
  const [finalCustomPoints, setFinalCustomPoints] = useState([]);
  const [eventType, setEventType] = useState("");
  const [prizes, setPrizes] = useState([
    { position: 1, prize: "", label: "", description: "" },
  ]);

  useEffect(() => {
    setCurrentPage(0);
    fetchSportData();
    fetchCompFee();
  }, []);

  useEffect(() => {
    if (selectedOrg && selectedSports) {
      fetchRoundData(
        selectedOrg,
        selectedSports,
        selectedSession,
        selectedOrgName,
      );
    }
  }, [selectedOrg]);

  const handleClose = () => {
    setOpenSocialShareModal(false);
    navigate("/tipscompetition/my-comps");
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const onHandleTimeChange = (newTime) => {
    handleTimeChange(newTime);
    const parsedTime = moment(newTime);
    const currentWeekDateTime = parsedTime.clone().day(selectedDay);
    if (
      moment(currentWeekDateTime.format()).format("YYYY-MM-DD HH:mm:ss") >
      moment(selectedRoundStartDate).format("YYYY-MM-DD HH:mm:ss")
    ) {
      setExpireStartTimeError(
        "Please select Day and Time Before Round Starting Time",
      );
    } else {
      setExpireStartTimeError("");
    }
  };

  const getFields = (list, field) => {
    return list.reduce(function (carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`,
      );
      if (status === 200) {
        const FilteredSportData = data?.result?.filter(
          (item) => item?.id === 12 || item?.id === 9 || item?.id === 4,
        );
        const releaseFilterSportData = FilteredSportData?.filter((item) =>
          release[Config.release]?.sportId?.includes(item?.id),
        );
        var sportsdata = releaseFilterSportData?.map((s) => {
          return { ...s, label: s?.sportName, value: s?.id };
        });

        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var filterSports = getFields(sdata, "id");
        setSelectedSports("");
        setSports(sdata);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`,
      );
      if (status === 200) {
        setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          item?.name !== null &&
            newdata.push({
              label: item?.name,
              value: item?.id,
              activeSeasonId: item?.activeSeason?.id ?? "",
            });
        });
        // fetchRoundData(selectedOrg, sID);
        let filterData = _.unionBy(OrgAll, newdata);
        // let allteamdatas = filterData?.unshift({
        //   label: "All Tournaments",
        //   value: 0
        // });
        setOrgAll(
          _.uniqBy(filterData, function (e) {
            return e.value;
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCompFee = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/global/web/tippingFees`,
      );
      if (status === 200) {
        setCompFee(data?.result?.value);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchRoundData = async (tID, sID, sessionId, tournament) => {
    setRounds([]);
    setRemainingRoundsCount("");
    const date = moment(Date()).format("YYYY-MM-DD");
    // &startDate=${date}
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/rounds?timezone=${timezone}&tournamentId=${tID}&SportId=${sID}${
          sID === 9 || sID === 12
            ? `&seasonId=${sessionId ? sessionId : ""}`
            : ""
        }`,
      );
      if (status === 200) {
        let newdata = [];

        const filteredRound = data?.roundList?.filter(
          (round) => !data?.completedRound.includes(round?.round),
        );
        let track = filteredRound?.map((item) => {
          newdata.push({
            label: `${
              item?.displayName
                ? item?.displayName
                : (sID == 4 ? "Day" : "Round") + " " + item?.round
            } ${
              item?.startTime
                ? "  (" +
                  moment(item?.startTime)
                    .tz(timezone)
                    .format("DD-MM-YYYY hh:mm A") +
                  ")"
                : ""
            }`,
            value: item?.round,
            roundStartDate: item?.startTime,
            displayName: item?.displayName,
            eventType: item?.eventType,
            numberOfWeeks: item?.numberOfWeeks,
          });
        });

        if (newdata && newdata?.length > 0) {
          setRounds(newdata);
          setNoRoundData("");
        } else {
          setNoRoundData(
            "There are no rounds available for this tournament!, Try selecting a different League.",
          );
        }

        let finalsRound = [];
        let finalsRoundList = data?.finalsRoundList?.map((item) => {
          finalsRound.push({
            round: item?.round,
            value: "",
          });
        });

        if (finalsRound && finalsRound?.length > 0) {
          setFinalCustomPoints(finalsRound);
        }

        // setRounds(
        //   newdata?.length > 0 ? newdata : roundOptions
        // );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`,
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.name,
              value: item?.id,
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function (e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };
  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };

  // const handleSave = async () => {
  //   const payload = {
  //     currentPassword: formvalues?.currentPassword,
  //     password: formvalues?.newPassword
  //   };
  // };

  // const clearDate = () => {
  //   setDateSelected(null);
  //   setOpen(false);
  // };
  const clearTime = () => {
    handleTimeChange(null);
    setTimeOpen(false);
  };

  const ifNoTipOptionsFunc = (value) => {
    if (value === 1) {
      return "ZERO_SCORE_ROUND";
    } else if (value === 2) {
      return "LOWEST_SCORE_ROUND";
    } else if (value === 3) {
      return "AWAY_TEAM_PICKED";
    } else {
      return "HOME_TEAM_PICKED";
    }
  };

  const handleValidation = () => {
    let flag = true;
    if (compName === "") {
      setCompNameError("Competition name is required");
      flag = false;
    } else {
      setCompNameError("");
      flag = true;
    }
    if (expireRoundError !== "") {
      flag = false;
    } else {
      flag = true;
    }
    if (expireStartTimeError !== "") {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };

  const submit = async () => {
    let payload = {
      SportId: formvalues?.selectSport,
      tournamentId: formvalues?.selectLeague,
      competitionName: formvalues?.compName,
      startingRound: formvalues?.startingRound,
      privacy: formvalues?.privacySettings === 1 ? "public" : "private",
      tipDeadline: formvalues?.tipDeadline === 1 ? "round" : "game",
      noTip: ifNoTipOptionsFunc(formvalues?.ifNoTip),
      numberOfTippers: formvalues?.numberOfTippers,
      tippingShow: formvalues?.showTipsterTipping,
      perfectRound: formvalues?.roundBonus === 1 ? true : false,
      perfectRoundPoints:
        formvalues?.customRoundBonusOption === 1
          ? formvalues?.roundBonusPoint
          : null,
      noOfJokerRound: formvalues?.numberOfJockerRound
        ? Number(formvalues?.numberOfJockerRound)
        : null,
      JokerRound: formvalues?.jokerRound === 1 ? true : false,
      marginRound: formvalues?.marginSystem === 1 ? true : false,
      about: compAbout,
      companyName: companyName,
      MediaId: cropImage?.id,
      tippingType: formvalues?.tipType,
      fees: formvalues?.entryFee === 1 ? true : false,
      entryFee: formvalues?.selectEntryFee
        ? Number(formvalues?.selectEntryFee)
        : null,
      prizeInfo: prizeInformationText ? prizeInformationText : "",
      prizes: managePrizeInformation === 1 ? true : false,
      prizeMoney: managePrizeInformation === 1 ? prizes : [],
      finalsRound: formvalues?.includeFinals === 1 ? true : false,
      finalsRoundPoints:
        formvalues?.includeFinals === 1 ? finalCustomPoints : [],
    };
    if (formvalues?.tipDeadline === 1) {
      payload = {
        ...payload,
        cutOffWeek: formvalues?.selectDay,
        cutOffTime: moment(formvalues?.selectTime)
          .tz(timezone)
          .format("HH:mm:ss"),
      };
    }
    if (formvalues?.selectSport === 9 || formvalues?.selectSport === 12) {
      payload = {
        ...payload,
        seasonId: selectedSession,
      };
    }
    if (handleValidation()) {
      setisLoading(true);
      try {
        const { status, data } = await axiosInstance.post(
          `tipping/createCompetition?timezone=${timezone}`,
          payload,
        );
        if (status === 200) {
          setisLoading(false);
          setShareCode(data?.result?.code);
          // setSelectedSports("");
          // setSelectedOrg("");

          setCreatedCompId(data?.result?.id);
          if (formvalues?.tipType === "winning") {
            handleSupportedTeamModal();
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
            setSelectedOrgName("");
            setSelectedSession("");
            setSelectedRound("");
            setSelectedRoundStartDate(null);
            setSelectedDay("");
            setSelectedPrivacy("");
            setSelectedTipDeadline("");
            setSelectedNoTip("");
            setDateSelected("");
            handleTimeChange("");
            setRoundBonusOption();
            setIncludeFinalsOption();
            setEntryFeeOption();
            setJokerBonusOption();
            setNumberOfJokerRound();
            setMarginSystemOption();
            setCompName("");
            setCompAbout("");
            setCompanyName("");
            setSelectedTipsterShown(null);
            setSelectedTipsterTippingShown(null);
            setSelectedTipsType("");
          } else {
            handlePaymentOpen();
          }
          setTimeout(() => {
            // setOpenSocialShareModal(true);
          }, 2000);
        } else {
          toast.error("An Error Occurred", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        }
      } catch (err) {
        setisLoading(false);
        toast.error(err?.response?.data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    }
  };

  const handlePaymentStatus = (paymentStatus) => {
    if (paymentStatus) {
      setSelectedOrgName("");
      setSelectedSession("");
      setSelectedRound("");
      setSelectedRoundStartDate(null);
      setSelectedDay("");
      setSelectedPrivacy("");
      setSelectedTipDeadline("");
      setSelectedNoTip("");
      setDateSelected("");
      handleTimeChange("");
      setRoundBonusOption();
      setIncludeFinalsOption();
      setEntryFeeOption();
      setEntryFeePoint();
      setJokerBonusOption();
      setNumberOfJokerRound();
      setMarginSystemOption();
      setCompName("");
      setCompAbout("");
      setCompanyName("");
      setSelectedTipsterShown(null);
      setSelectedTipsterTippingShown(null);
      setSelectedTipsType("");
      setPrizeInformationText("");
      setManagePrizeInformation();
    }
  };

  const handleImageModal = () => {
    setImageModalOpen(true);
  };

  const handleImageClose = () => {
    setImageModalOpen(false);
  };

  const getImage = (data) => {
    setCropImage(data);
  };

  const handleSupportedTeamModal = () => {
    setOpenSupportedTeamModal(true);
  };

  const handleSupportedTeamModalClose = () => {
    setOpenSupportedTeamModal(false);
    navigate("/tipscompetition/my-comps");
  };

  const handlePaymentOpen = () => {
    setPaymentModalOpen(true);
  };

  const handlePaymentClose = () => {
    setPaymentModalOpen(false);
  };

  const handleFinalChange = (index, value) => {
    setFinalCustomPoints((prev) =>
      prev.map((item, i) => (i === index ? { ...item, value } : item)),
    );
  };

  const handlePrizeChange = (index, value) => {
    const updatedPrizes = [...prizes];
    updatedPrizes[index].prize = value;
    setPrizes(updatedPrizes);
  };

  const addPosition = () => {
    const maxPosition =
      prizes?.length > 0 ? Math.max(...prizes?.map((p) => p?.position)) : 0;
    setPrizes([
      ...prizes,
      { position: maxPosition + 1, prize: "", description: "", label: "" },
    ]);
  };
  const removePosition = (index) => {
    if (prizes?.length > 1) {
      const updatedPrizes = prizes
        ?.filter((_, i) => i !== index)
        ?.map((item, i) => ({ ...item, position: i + 1 })); // Renumber positions
      setPrizes(updatedPrizes);
    }
  };

  const handleLabelChange = (index, value) => {
    setPrizes((prevPrizes) => {
      const updatedPrizes = [...prevPrizes];
      updatedPrizes[index].label = value;
      return updatedPrizes;
    });
  };

  const handleDescChange = (index, value) => {
    setPrizes((prevPrizes) => {
      const updatedPrizes = [...prevPrizes];
      updatedPrizes[index].description = value;
      return updatedPrizes;
    });
  };

  return (
    <>
      <Box className="content-wrap create-comp-content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerHeader : banner,
          )}
        </Box>
        <Box className="comp-wrap">
          <Box className="comp-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Link
                  underline="hover"
                  color="inherit"
                  to=""
                  className="cursor-default"
                >
                  Tipping Competition
                </Link>

                <Typography>Create Comps</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Create Comps</Typography>
          </Box>

          <Box className="page-deatils-wrap">
            <Formik
              ref={formikRef}
              enableReinitialize
              initialValues={{
                selectSport: selectedSports,
                selectLeague: selectedOrg ? selectedOrg : "",
                compName: compName,
                // compAbout: compAbout,
                startingRound: selectedRound,
                privacySettings: selectedPrivacy,
                tipDeadline: selectedTipDeadline,
                selectDay: selectedDay,
                // dateSelected
                selectTime: selectedTime,
                ifNoTip: selectedNoTip,
                numberOfTippers: selectedTipsterShown,
                showTipsterTipping: selectedTipsterTippingShown,
                tipType: selectedTipsType,
                roundBonus: roundBonusOption,
                customRoundBonusOption: customRoundBonusOption,
                roundBonusPoint: customRoundBonusPoint,
                includeFinals: includeFinalsOption,
                entryFee: entryFeeOption,
                selectEntryFee: entryFeePoint,
                jokerRound: jokerBonusOption,
                numberOfJockerRound: numberOfJokerRound,
                marginSystem: marginSystemOption,
                eventType: eventType,
              }}
              validationSchema={Yup.object().shape({
                selectSport: Yup.string().required("Sport is required"),
                selectLeague: Yup.string().required("League is required"),
                compName: Yup.string()
                  .trim()
                  .required("Competition name is required"),
                // .matches(
                //   /^[^0-9]+$/,
                //   "Numbers are not allowed for Competition Name"
                // ),
                // .test(
                //   "compName",
                //   "Only letters and Spacial characters are allowed for Competition Name",
                //   value => value && value.match(/^[a-zA-Z\s'()-]+$/)
                //   // value.match(
                //   //   /^\p{L}[\p{L}'.\s]*(?:[\-]{0,1}[\p{L}.'\s]*\p{L}+)*$/gu
                //   // )
                // )
                startingRound: Yup.string().required(
                  "Starting round is required",
                ),
                privacySettings: Yup.string().required(
                  "Privacy settings is required",
                ),
                tipDeadline: Yup.string().required("Tip deadline is required"),
                ifNoTip: Yup.string().required("This field is required"),
                selectDay: Yup.string()
                  .nullable()
                  .when("tipDeadline", (tipDeadline) => {
                    return tipDeadline?.includes("1")
                      ? Yup.string().required("Choose a Day")
                      : Yup.string().notRequired();
                  }),

                selectTime: Yup.string()
                  .nullable()
                  .when("tipDeadline", (tipDeadline) => {
                    return tipDeadline?.includes("1")
                      ? Yup.string().required("Choose a Time")
                      : Yup.string().notRequired();
                  }),
                numberOfTippers: Yup.boolean()
                  .nullable()
                  .required("This field is required"),
                showTipsterTipping: Yup.string()
                  .nullable()
                  .required("This field is required"),
                tipType: Yup.string()
                  .nullable()
                  .required("This field is required"),
                roundBonus: Yup.string()
                  .nullable()
                  .when("tipType", (tipType) => {
                    return tipType?.includes("winning")
                      ? Yup.string().required("This field is required")
                      : Yup.string().notRequired();
                  }),
                customRoundBonusOption: Yup.string().notRequired(),
                roundBonusPoint: Yup.string()
                  .nullable()
                  .when("customRoundBonusOption", (customRoundBonusOption) => {
                    return customRoundBonusOption?.includes("1")
                      ? Yup.string().required("This field is required")
                      : Yup.string().notRequired();
                  }),
                eventType: Yup.string().notRequired(),
                includeFinals: Yup.string()
                  .nullable()
                  .when("tipType", (tipType) => {
                    return tipType?.includes("winning")
                      ? Yup.string().required("This field is required")
                      : Yup.string().notRequired();
                  }),
                // .when(["eventType", "tipType"], {
                //   is: (eventType, tipType) => {
                //     return (
                //       eventType?.includes("finale") &&
                //       tipType?.includes("winning")
                //     );
                //   },
                //   then: (schema) => schema.required("This field is required"),
                //   otherwise: (schema) => schema.notRequired(),
                // }),

                entryFee: Yup.string()
                  .nullable()
                  .required("This field is required"),
                selectEntryFee: Yup.string()
                  .nullable()
                  .when("entryFee", (entryFee) => {
                    return entryFee?.includes("1")
                      ? Yup.string().required("This field is required")
                      : Yup.string().notRequired();
                  }),
                jokerRound: Yup.string()
                  .nullable()
                  .when("tipType", (tipType) => {
                    return tipType?.includes("winning")
                      ? Yup.string().required("This field is required")
                      : Yup.string().notRequired();
                  }),
                // numberOfJockerRound: Yup.string()
                //   .nullable()
                //   .when("jokerRound", (jokerRound) => {
                //     return jokerRound?.includes("1")
                //       ? Yup.string().required("This field is required")
                //       : Yup.string().notRequired();
                //   }),
                numberOfJockerRound: Yup.number()
                  .nullable()
                  .when("jokerRound", (jokerRound, schema) => {
                    return jokerRound?.includes("1")
                      ? schema
                          .required("This field is required")
                          .min(1, "Minimum value is 1")
                          .max(
                            remainingRoundsCount,
                            `Max limit is ${remainingRoundsCount}`,
                          )
                      : schema.notRequired();
                  }),
                marginSystem: Yup.string()
                  .nullable()
                  .when("tipType", (tipType) => {
                    return tipType?.includes("winning")
                      ? Yup.string().required("This field is required")
                      : Yup.string().notRequired();
                  }),
              })}
              onSubmit={(reqdata) => {
                setformvalues({ ...formvalues, ...reqdata });
                handleValidation();
                submit();
                // if (handleValidation()) {
                //   handleSupportedTeamModal();
                // }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                setFieldError,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box className="create-comp-details-wrap">
                    <Accordion
                      defaultExpanded
                      className="create-comp-accordion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography className="accordion-header-text">
                          Comp Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box className="comp-details">
                          <Typography className="textfield-text">
                            Comp Image:
                          </Typography>
                          <Box className="comp-image-name-wrap">
                            <Box
                              className="comp-image-wrap"
                              onClick={() => handleImageModal()}
                            >
                              <img
                                className="comp-image"
                                src={
                                  cropImage?.filePath
                                    ? Config?.countryMediaURL +
                                      cropImage?.filePath
                                    : compImage
                                }
                                alt="comp-image"
                              />
                              <AddButton className="add-button" />
                            </Box>
                            <Box
                              className="comp-image-text-wrap"
                              onClick={() => handleImageModal()}
                            >
                              <Typography className="image-text">
                                Upload Comp Image
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="comp-details ">
                          <Typography className="textfield-text">
                            About Comp:
                          </Typography>
                          <Box className="field-container">
                            <TextareaAutosize
                              id="outlined-basic"
                              variant="outlined"
                              className="details-textarea-field"
                              placeholder="- About -"
                              name="compAbout"
                              maxRows={3}
                              minRows={2}
                              onChange={(e) => setCompAbout(e?.target?.value)}
                              value={compAbout}
                              // error={Boolean(touched?.compAbout && errors?.compAbout)}
                              // helperText={touched?.compAbout ? errors?.compAbout : ""}
                            />
                          </Box>
                        </Box>
                        <Box className="comp-details">
                          <Typography className="textfield-text">
                            Select Sport<span className="danger">*</span>:
                          </Typography>
                          <Box className="field-container">
                            <Select
                              className="React sort-select"
                              value={
                                selectedSports !== "" &&
                                sports?.find((item) => {
                                  return item?.value === selectedSports;
                                })
                              }
                              onChange={(e) => {
                                setSelectedSports(e?.value);
                                setCurrentPage(0);
                                // setSelectedTeams(null);
                                setSelectedOrg(null);
                                setSelectedSession("");
                                setSelectedOrgName("");
                                setTeamsAll([]);
                                setOrgAll([]);
                                setRounds([]);
                                setRemainingRoundsCount("");
                                // if (!e?.isAll) {
                                fetchOrgData(0, e?.value, []);
                                // }
                              }}
                              // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
                              options={sports}
                              classNamePrefix="select"
                              placeholder="- Select Sport -"
                              // isSearchable={false}
                              // onFocus={() => setTournamentSelect(true)}
                              // onBlur={() => setTournamentSelect(false)}
                              components={{ DropdownIndicator }}
                            />

                            <span className="text-danger">
                              {touched?.selectSport && errors?.selectSport
                                ? errors?.selectSport
                                : ""}
                            </span>
                          </Box>
                        </Box>
                        <Box className="comp-details">
                          <Typography className="textfield-text">
                            Select League<span className="danger">*</span>:
                          </Typography>
                          <Box className="field-container">
                            <Select
                              className="React sort-select"
                              onMenuScrollToBottom={(e) =>
                                handleOnScrollBottomOrg(e)
                              }
                              onInputChange={(e) => {
                                handleOrgInputChange(0, e, selectedSports);
                              }}
                              onChange={(e) => {
                                // fetchRoundData(e?.value, selectedSports);
                                setSelectedOrg(e?.value ? e?.value : "");
                                setSelectedOrgName(e?.label ? e?.label : "");
                                setSelectedSession(
                                  e?.activeSeasonId ? e?.activeSeasonId : "",
                                );
                                // setSelectedTeams(null);
                                setSelectedRound("");
                                setSelectedRoundStartDate(null);
                                setOrgApiCount(0);
                                setpageOrg(0);
                                setisOrgSelectOpen(false);
                                setCurrentPage(0);
                              }}
                              onFocus={() => setisOrgSelectOpen(true)}
                              onBlur={() => setisOrgSelectOpen(false)}
                              value={
                                selectedOrg &&
                                (isOrgSearch
                                  ? searchOrg?.find((item) => {
                                      return item?.value == selectedOrg;
                                    })
                                  : OrgAll?.find((item) => {
                                      return item?.value == selectedOrg;
                                    }))
                              }
                              options={isOrgSearch ? searchOrg : OrgAll}
                              classNamePrefix="select"
                              placeholder="- Select League -"
                              isDisabled={!selectedSports ? true : false}
                              // isDisabled={
                              //   selectedSports && selectedSports.length > 0
                              //     ? true
                              //     : false
                              // }
                              components={{ DropdownIndicator }}
                            />
                            <span className="text-danger">
                              {touched?.selectLeague && errors?.selectLeague
                                ? errors?.selectLeague
                                : ""}
                            </span>
                          </Box>
                        </Box>
                        <Box className="comp-details">
                          <Typography className="textfield-text">
                            Competition name<span className="danger">*</span>:
                          </Typography>
                          <Box className="field-container">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              className="comp-textfield"
                              placeholder="Choose a name"
                              name="compName"
                              onChange={(e) => setCompName(e?.target?.value)}
                              value={compName}
                              error={Boolean(
                                touched?.compName && errors?.compName,
                              )}
                              helperText={
                                touched?.compName ? errors?.compName : ""
                              }
                            />
                            {/* <span className="text-danger">
                        {!compName && compNameError ? compNameError : ""}
                      </span> */}
                          </Box>
                        </Box>
                        <Box className="comp-details">
                          <Typography className="textfield-text">
                            Company name :
                          </Typography>
                          <Box className="field-container">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              className="comp-textfield"
                              placeholder="Choose a company name"
                              name="companyName"
                              onChange={(e) => setCompanyName(e?.target?.value)}
                              value={companyName}
                            />
                          </Box>
                        </Box>
                        <Box className="comp-details">
                          <Typography className="textfield-text">
                            Starting Round<span className="danger">*</span>:
                          </Typography>
                          <Box className="field-container">
                            <Select
                              className="React sort-select"
                              value={
                                selectedRound !== "" &&
                                rounds?.find((item) => {
                                  return item?.value === selectedRound;
                                })
                              }
                              options={rounds}
                              onChange={(e) => {
                                setSelectedRound(e?.value);
                                setSelectedRoundStartDate(e?.roundStartDate);
                                handleTimeChange("");
                                setExpireStartTimeError("");
                                setEventType(e?.eventType);
                                // Count of remaining rounds
                                const selectedIndex = rounds.findIndex(
                                  (item) => item.value === e?.value,
                                );
                                const remainingRounds =
                                  rounds.length - selectedIndex;

                                setRemainingRoundsCount(remainingRounds); // Store in state if needed
                                if (moment(e?.roundStartDate) < moment()) {
                                  setExpireRoundError(
                                    "You can not select this Round!, Please Try another round",
                                  );
                                } else {
                                  setExpireRoundError("");
                                }
                              }}
                              classNamePrefix="select"
                              placeholder="- Select Round -"
                              components={{ DropdownIndicator }}
                              isDisabled={
                                rounds?.length === 0 && noRoundData !== ""
                              }
                            />
                            <span className="text-danger">
                              {rounds?.length === 0 && noRoundData
                                ? noRoundData
                                : ""}
                              {expireRoundError && expireRoundError !== ""
                                ? expireRoundError
                                : ""}
                            </span>
                            <span className="text-danger">
                              {touched?.startingRound && errors?.startingRound
                                ? errors?.startingRound
                                : ""}
                            </span>
                          </Box>
                        </Box>
                        <Box className="comp-details">
                          <Typography className="textfield-text">
                            Privacy settings<span className="danger">*</span>:
                          </Typography>
                          <Box className="field-container">
                            <Select
                              className="React sort-select"
                              value={
                                selectedPrivacy !== "" &&
                                privacyOptions?.find((item) => {
                                  return item?.value === selectedPrivacy;
                                })
                              }
                              onChange={(e) => setSelectedPrivacy(e?.value)}
                              options={privacyOptions}
                              classNamePrefix="select"
                              placeholder="- Select -"
                              components={{ DropdownIndicator }}
                            />
                            <span className="text-danger">
                              {touched?.privacySettings &&
                              errors?.privacySettings
                                ? errors?.privacySettings
                                : ""}
                            </span>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      defaultExpanded
                      className="create-comp-accordion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography className="accordion-header-text">
                          Select Tipping and Points
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box className="comp-details">
                          <Typography className="textfield-text tipping-type">
                            Select Tipping<span className="danger">*</span>
                            <span className="info-icon">
                              <Tooltip
                                disableFocusListener
                                title={
                                  <>
                                    <Box>
                                      {tippingTypeOption?.map((item) => {
                                        return (
                                          <Box className="comp-type-wrap">
                                            <span className="comp-type">
                                              {item?.type}:{" "}
                                            </span>
                                            <span className="comp-des">
                                              {item?.description}
                                            </span>
                                          </Box>
                                        );
                                      })}
                                    </Box>
                                  </>
                                }
                                placement="right-start"
                                arrow={true}
                                classes={{
                                  tooltip: "info-tooltip",
                                }}
                                PopperProps={{
                                  popper: "info-tooltip",
                                }}
                              >
                                <InfoIcon />
                              </Tooltip>
                            </span>
                            :
                          </Typography>
                          <Box className="field-container">
                            <Box className="Filteritemlist-wrap">
                              <Box className="field-container">
                                <Box className="filter-list-racing">
                                  <ul className="Filteritemlist-racing tipping-type-racing">
                                    {tippingTypeRadio?.map((obj, i) => {
                                      if (
                                        selectedSports == 4 &&
                                        obj?.id === 3
                                      ) {
                                        return null;
                                      } else {
                                        return (
                                          <li key={i}>
                                            <FormControl>
                                              <label>
                                                <Checkbox
                                                  className="filter-racing"
                                                  icon={<Unchecked />}
                                                  checkedIcon={<Checked />}
                                                  name="filter"
                                                  onChange={() => {
                                                    setSelectedTipsType(
                                                      obj?.value,
                                                    );
                                                    setRoundBonusOption();
                                                    setJokerBonusOption();
                                                    setNumberOfJokerRound();
                                                    setMarginSystemOption();
                                                  }}
                                                  checked={
                                                    selectedTipsType ===
                                                    obj?.value
                                                  }
                                                />
                                                <span className="badge-icon-wrap">
                                                  {obj?.icon ? obj?.icon : ""}
                                                  {obj?.label}
                                                </span>
                                              </label>
                                            </FormControl>
                                          </li>
                                        );
                                      }
                                    })}
                                  </ul>
                                </Box>
                                <span className="purchase-info">
                                  To create a Premium Odds tipping competition
                                  or Spread/Line tipping, a ${compFee} fee is
                                  required.
                                </span>
                                <span className="text-danger">
                                  {touched?.tipType && errors?.tipType
                                    ? errors?.tipType
                                    : ""}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="comp-details">
                          <Typography className="textfield-text">
                            Tip deadline<span className="danger">*</span>:
                          </Typography>
                          <Box className="field-container">
                            <Select
                              className="React sort-select"
                              value={
                                selectedTipDeadline !== "" &&
                                tipDeadlineOptions?.find((item) => {
                                  return item?.value === selectedTipDeadline;
                                })
                              }
                              onChange={(e) => setSelectedTipDeadline(e?.value)}
                              options={tipDeadlineOptions}
                              classNamePrefix="select"
                              placeholder="- Select Deadline -"
                              components={{ DropdownIndicator }}
                            />
                            <span className="text-danger">
                              {touched?.tipDeadline && errors?.tipDeadline
                                ? errors?.tipDeadline
                                : ""}
                            </span>
                          </Box>
                        </Box>
                        {selectedTipDeadline == 1 ? (
                          <Box className="day-time-section">
                            <Box className="day-time-box yes-no-box-wrap">
                              <Typography className="cutoff-txt">
                                Select a day and time for cut off
                              </Typography>

                              <Box className="day-time">
                                <Box className="comp-date-selected-wrap">
                                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  clearable
                                  autoOk
                                  // disableToolbar
                                  // variant="inline"
                                  format="dd/MM/yyyy"
                                  placeholder="- Select day -"
                                  margin="normal"
                                  id="date-picker-inline"
                                  inputVariant="outlined"
                                  value={dateSelected ? dateSelected : null}
                                  onChange={(e) => handleLiveDateChange(e)}
                                  onClear={clearDate}
                                  open={open}
                                  onOpen={() => setOpen(true)}
                                  onClose={() => setOpen(false)}
                                  views={["year", "month", "date"]}
                                  KeyboardButtonProps={{
                                    "aria-label": "Select Date",
                                  }}
                                  keyboardIcon={
                                    <Datepicker className="keyboard-icon" />
                                  }
                                  // className="comp-comp-details-search-picker"
                                />
                              </MuiPickersUtilsProvider> */}
                                  <Box className="field-container">
                                    <Select
                                      className="React day-select"
                                      value={
                                        selectedDay !== "" &&
                                        dayOptions?.find((item) => {
                                          return item?.label === selectedDay;
                                        })
                                      }
                                      onChange={(e) => {
                                        setSelectedDay(e?.label);
                                        if (
                                          selectedTime &&
                                          selectedTime != ""
                                        ) {
                                          const parsedTime =
                                            moment(selectedTime);
                                          const currentWeekDateTime = parsedTime
                                            .clone()
                                            .day(e?.label);
                                          if (
                                            moment(
                                              currentWeekDateTime.format(),
                                            ).format("YYYY-MM-DD HH:mm:ss") >
                                            moment(
                                              selectedRoundStartDate,
                                            ).format("YYYY-MM-DD HH:mm:ss")
                                          ) {
                                            setExpireStartTimeError(
                                              "Please select Day and Time Before Round Starting Time",
                                            );
                                          } else {
                                            setExpireStartTimeError("");
                                          }
                                        }
                                      }}
                                      options={dayOptions}
                                      classNamePrefix="select"
                                      placeholder="Select Day"
                                      components={{ DropdownIndicator }}
                                      isDisabled={
                                        expireRoundError
                                          ? true
                                          : selectedRound == "0"
                                            ? false
                                            : !selectedRound
                                      }
                                    />
                                  </Box>
                                  <span className="text-danger">
                                    {touched?.selectDay && errors?.selectDay
                                      ? errors?.selectDay
                                      : ""}
                                  </span>
                                </Box>
                                <Box className="comp-date-selected-wrap border-gray">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <MobileTimePicker
                                      clearable
                                      autoOk
                                      onClear={clearTime}
                                      placeholder="--:--"
                                      value={selectedTime ? selectedTime : null}
                                      onChange={(e) => onHandleTimeChange(e)}
                                      ampm={false}
                                      open={timeOpen}
                                      onOpen={() => setTimeOpen(true)}
                                      onClose={() => setTimeOpen(false)}
                                      format="HH:mm"
                                      // TextFieldComponent={(props) => (
                                      //   <TextField
                                      //     {...props}
                                      //     // className="comp-comp-details-search-picker"
                                      //     InputProps={{
                                      //       endAdornment: (
                                      //         <InputAdornment position="end">
                                      //           <IconButton size="large">
                                      //             <Timepicker className="keyboard-icon" />
                                      //           </IconButton>
                                      //         </InputAdornment>
                                      //       ),
                                      //     }}
                                      //   />
                                      // )}
                                      slots={{
                                        openPickerIcon: Timepicker, // Custom icon
                                      }}
                                      slotProps={{
                                        field: {
                                          id: "date-picker-inline",
                                          placeholder: "--:--",
                                        },
                                      }}
                                      disabled={
                                        expireRoundError
                                          ? true
                                          : selectedRound == "0"
                                            ? false
                                            : !selectedRound
                                      }
                                    />
                                  </LocalizationProvider>
                                  <span className="text-danger">
                                    {touched?.selectTime && errors?.selectTime
                                      ? errors?.selectTime
                                      : ""}
                                  </span>
                                </Box>
                              </Box>
                              <span className="text-danger">
                                {expireStartTimeError &&
                                expireStartTimeError !== ""
                                  ? expireStartTimeError
                                  : ""}
                              </span>
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )}
                        <Box className="comp-details">
                          <Typography className="textfield-text">
                            If no tip is entered
                            <span className="danger">*</span>:
                          </Typography>
                          <Box className="field-container">
                            <Select
                              className="React sort-select"
                              value={
                                selectedNoTip !== "" &&
                                ifNoTipOptions?.find((item) => {
                                  return item?.value === selectedNoTip;
                                })
                              }
                              onChange={(e) => setSelectedNoTip(e?.value)}
                              options={ifNoTipOptions}
                              classNamePrefix="select"
                              placeholder="- Select -"
                              components={{ DropdownIndicator }}
                            />
                            <span className="text-danger">
                              {touched?.ifNoTip && errors?.ifNoTip
                                ? errors?.ifNoTip
                                : ""}
                            </span>
                          </Box>
                        </Box>
                        <Box className="comp-details jc-start">
                          <Typography className="textfield-text">
                            Number of Tipsters<span className="danger">*</span>:
                          </Typography>
                          <Box className="Filteritemlist-wrap">
                            <Box className="field-container">
                              <Box className="filter-list-racing">
                                <ul className="Filteritemlist-racing">
                                  {isTipsterShown?.length > 0 &&
                                    isTipsterShown?.map((obj, i) => (
                                      <li key={i}>
                                        <FormControl>
                                          <label>
                                            <Checkbox
                                              className="filter-racing"
                                              icon={<Unchecked />}
                                              checkedIcon={<Checked />}
                                              name="filter"
                                              onChange={() =>
                                                setSelectedTipsterShown(
                                                  obj?.value,
                                                )
                                              }
                                              checked={
                                                selectedTipsterShown ===
                                                obj?.value
                                              }
                                            />
                                            {obj?.isTipsterShown}
                                          </label>
                                        </FormControl>
                                      </li>
                                    ))}
                                </ul>
                              </Box>
                              <span className="text-danger">
                                {touched?.numberOfTippers &&
                                errors?.numberOfTippers
                                  ? errors?.numberOfTippers
                                  : ""}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="comp-details jc-start">
                          <Typography className="textfield-text">
                            Show Tipster's Tipping
                            <span className="danger">*</span>:
                          </Typography>
                          <Box className="Filteritemlist-wrap tipster-tiiping-wrap">
                            <Box className="field-container">
                              <Box className="filter-list-racing">
                                <ul className="Filteritemlist-racing">
                                  {isTipsterTipping?.length > 0 &&
                                    isTipsterTipping?.map((obj, i) => (
                                      <li key={i}>
                                        <FormControl>
                                          <label>
                                            <Checkbox
                                              className="filter-racing"
                                              icon={<Unchecked />}
                                              checkedIcon={<Checked />}
                                              name="filter"
                                              onChange={() =>
                                                setSelectedTipsterTippingShown(
                                                  obj?.value,
                                                )
                                              }
                                              checked={
                                                selectedTipsterTippingShown ===
                                                obj?.value
                                              }
                                            />
                                            {obj?.isTipsterTippingShown}
                                          </label>
                                        </FormControl>
                                      </li>
                                    ))}
                                </ul>
                              </Box>
                              <span className="text-danger">
                                {touched?.showTipsterTipping &&
                                errors?.showTipsterTipping
                                  ? errors?.showTipsterTipping
                                  : ""}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          className="comp-details jc-start"
                          style={{
                            opacity:
                              formvalues?.tipType !== "winning" ? 0.6 : 1,
                          }}
                        >
                          <Typography className="textfield-text">
                            Perfect round Bonus<span className="danger">*</span>
                            :
                          </Typography>
                          <Box className="Filteritemlist-wrap">
                            <Box className="field-container">
                              <Box className="filter-list-racing">
                                <ul className="Filteritemlist-racing">
                                  {roundBonus?.length > 0 &&
                                    roundBonus?.map((obj, i) => (
                                      <li key={i}>
                                        <FormControl>
                                          <label>
                                            <Checkbox
                                              className="filter-racing"
                                              icon={<Unchecked />}
                                              checkedIcon={<Checked />}
                                              name="filter"
                                              onChange={() => {
                                                setRoundBonusOption(obj?.id);
                                                setCustomRoundBonusPoint(null);
                                                setCustomRoundBonusOption();
                                              }}
                                              checked={
                                                roundBonusOption === obj?.id
                                              }
                                              disabled={
                                                formvalues?.tipType !==
                                                "winning"
                                              }
                                            />
                                            {obj?.Roundvalue}
                                          </label>
                                        </FormControl>
                                      </li>
                                    ))}
                                </ul>
                              </Box>
                              <span className="text-danger">
                                {touched?.roundBonus && errors?.roundBonus
                                  ? errors?.roundBonus
                                  : ""}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                        {roundBonusOption === 1 ? (
                          <Box className="day-time-section">
                            <Box className="day-time-box yes-no-box-wrap">
                              <Typography className="cutoff-txt">
                                Custom Perfect round Bonus Points
                              </Typography>

                              <Box className="Filteritemlist-wrap">
                                <Box className="field-container">
                                  <Box className="filter-list-racing">
                                    <ul className="Filteritemlist-racing">
                                      {roundBonus?.length > 0 &&
                                        roundBonus?.map((obj, i) => (
                                          <li key={i}>
                                            <FormControl>
                                              <label>
                                                <Checkbox
                                                  className="filter-racing"
                                                  icon={<Unchecked />}
                                                  checkedIcon={<Checked />}
                                                  name="filter"
                                                  onChange={() => {
                                                    setCustomRoundBonusOption(
                                                      obj?.id,
                                                    );
                                                    setCustomRoundBonusPoint(
                                                      null,
                                                    );
                                                  }}
                                                  checked={
                                                    customRoundBonusOption ===
                                                    obj?.id
                                                  }
                                                />
                                                {obj?.Roundvalue}
                                              </label>
                                            </FormControl>
                                          </li>
                                        ))}
                                    </ul>
                                  </Box>
                                </Box>
                              </Box>
                              {customRoundBonusOption === 1 && (
                                <Box className="field-container inner-field-container">
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    className="comp-textfield"
                                    placeholder="Custom Points"
                                    name="customRoundBonusPoint"
                                    type="number"
                                    onChange={(e) =>
                                      setCustomRoundBonusPoint(e?.target?.value)
                                    }
                                    value={customRoundBonusPoint ?? ""}
                                    disabled={customRoundBonusOption !== 1}
                                  />
                                  <span className="text-danger">
                                    {touched?.roundBonusPoint &&
                                    errors?.roundBonusPoint
                                      ? errors?.roundBonusPoint
                                      : ""}
                                  </span>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )}
                        {finalCustomPoints?.length > 0 && (
                          <>
                            <Box
                              className="comp-details jc-start"
                              style={{
                                opacity:
                                  formvalues?.tipType !== "winning" ? 0.6 : 1,
                              }}
                            >
                              <Typography className="textfield-text">
                                Include Finals<span className="danger">*</span>:
                              </Typography>
                              <Box className="Filteritemlist-wrap">
                                <Box className="field-container">
                                  <Box className="filter-list-racing">
                                    <ul className="Filteritemlist-racing">
                                      {includeFinals?.length > 0 &&
                                        includeFinals?.map((obj, i) => (
                                          <li key={i}>
                                            <FormControl>
                                              <label>
                                                <Checkbox
                                                  className="filter-racing"
                                                  icon={<Unchecked />}
                                                  checkedIcon={<Checked />}
                                                  name="filter"
                                                  onChange={() =>
                                                    setIncludeFinalsOption(
                                                      obj?.id,
                                                    )
                                                  }
                                                  checked={
                                                    includeFinalsOption ===
                                                    obj?.id
                                                  }
                                                  disabled={
                                                    formvalues?.tipType !==
                                                    "winning"
                                                  }
                                                />
                                                {obj?.IncludeFinalsValue}
                                              </label>
                                            </FormControl>
                                          </li>
                                        ))}
                                    </ul>
                                  </Box>
                                  <span className="text-danger">
                                    {touched?.includeFinals &&
                                    errors?.includeFinals
                                      ? errors?.includeFinals
                                      : ""}
                                  </span>
                                </Box>
                              </Box>
                            </Box>
                            {includeFinalsOption === 1 && (
                              <Box className="day-time-section">
                                <Box className="day-time-box yes-no-box-wrap">
                                  <Typography className="cutoff-txt">
                                    Custom Points for Finals
                                  </Typography>

                                  <Box className="Filteritemlist-wrap">
                                    <Box className="field-container">
                                      <Box className="filter-list-racing">
                                        <ul className="Filteritemlist-racing">
                                          {includeFinals?.length > 0 &&
                                            includeFinals?.map((obj, i) => (
                                              <li key={i}>
                                                <FormControl>
                                                  <label>
                                                    <Checkbox
                                                      className="filter-racing"
                                                      icon={<Unchecked />}
                                                      checkedIcon={<Checked />}
                                                      name="filter"
                                                      onChange={() =>
                                                        setIncludeFinalsPoint(
                                                          obj?.id,
                                                        )
                                                      }
                                                      checked={
                                                        includeFinalsPoint ===
                                                        obj?.id
                                                      }
                                                    />
                                                    {obj?.IncludeFinalsValue}
                                                  </label>
                                                </FormControl>
                                              </li>
                                            ))}
                                        </ul>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {includeFinalsPoint === 1 && (
                                    <Box className="field-container inner-field-container finals-point-container">
                                      {finalCustomPoints?.map((item, index) => (
                                        <Box key={index}>
                                          <label>Week {item?.round}</label>
                                          <TextField
                                            id={`outlined-basic-${index}`}
                                            variant="outlined"
                                            className="comp-textfield"
                                            placeholder="Custom Points"
                                            name={`customPoints-${index}`}
                                            type="number"
                                            value={item?.value}
                                            onChange={(e) =>
                                              handleFinalChange(
                                                index,
                                                e.target.value,
                                              )
                                            }
                                            disabled={includeFinalsPoint !== 1}
                                          />
                                        </Box>
                                      ))}
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            )}
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      defaultExpanded
                      className="create-comp-accordion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography className="accordion-header-text">
                          Other
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          className="comp-details jc-start"
                          style={{
                            opacity:
                              formvalues?.tipType !== "winning" ? 0.6 : 1,
                          }}
                        >
                          <Typography className="textfield-text text-field-flex">
                            Include Joker Round <Joker />
                            <span className="danger">*</span>:
                          </Typography>
                          <Box className="Filteritemlist-wrap">
                            <Box className="field-container">
                              <Box className="filter-list-racing">
                                <ul className="Filteritemlist-racing">
                                  {jokerBonus?.length > 0 &&
                                    jokerBonus?.map((obj, i) => (
                                      <li key={i}>
                                        <FormControl>
                                          <label>
                                            <Checkbox
                                              className="filter-racing"
                                              icon={<Unchecked />}
                                              checkedIcon={<Checked />}
                                              name="filter"
                                              onChange={() =>
                                                setJokerBonusOption(obj?.id)
                                              }
                                              checked={
                                                jokerBonusOption === obj?.id
                                              }
                                              disabled={
                                                formvalues?.tipType !==
                                                "winning"
                                              }
                                            />
                                            {obj?.Jokervalue}
                                          </label>
                                        </FormControl>
                                      </li>
                                    ))}
                                </ul>
                              </Box>
                              <span className="text-danger">
                                {touched?.jokerRound && errors?.jokerRound
                                  ? errors?.jokerRound
                                  : ""}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                        {jokerBonusOption === 1 && (
                          <Box className="day-time-section">
                            <Box className="day-time-box yes-no-box-wrap">
                              <Typography className="cutoff-txt">
                                Select number of Joker Rounds
                              </Typography>

                              <Box className="field-container inner-field-container">
                                <TextField
                                  id="outlined-basic"
                                  variant="outlined"
                                  className="comp-textfield"
                                  placeholder="No. of Joker Rounds"
                                  name="numberOfJokerRound"
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setNumberOfJokerRound(e?.target?.value); // Ensure the value doesn't exceed remainingRoundsCount
                                    if (value > remainingRoundsCount) {
                                      setFieldError(
                                        "numberOfJockerRound",
                                        `Max limit is ${remainingRoundsCount}`,
                                      );
                                    } else {
                                      setFieldError("numberOfJockerRound", "");
                                    }

                                    setFieldValue("numberOfJockerRound", value);
                                  }}
                                  type="number"
                                  value={numberOfJokerRound}
                                  disabled={jokerBonusOption !== 1}
                                />
                                <span className="text-danger">
                                  {touched?.numberOfJockerRound &&
                                  errors?.numberOfJockerRound
                                    ? errors?.numberOfJockerRound
                                    : ""}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        <Box
                          className="comp-details jc-start"
                          style={{
                            opacity:
                              formvalues?.tipType !== "winning" ? 0.6 : 1,
                          }}
                        >
                          <Typography className="textfield-text">
                            Include Margin System
                            <span className="danger">*</span>:
                          </Typography>
                          <Box className="Filteritemlist-wrap">
                            <Box className="field-container">
                              <Box className="filter-list-racing">
                                <ul className="Filteritemlist-racing">
                                  {marginSystem?.length > 0 &&
                                    marginSystem?.map((obj, i) => (
                                      <li key={i}>
                                        <FormControl>
                                          <label>
                                            <Checkbox
                                              className="filter-racing"
                                              icon={<Unchecked />}
                                              checkedIcon={<Checked />}
                                              name="filter"
                                              onChange={() =>
                                                setMarginSystemOption(obj.id)
                                              }
                                              checked={
                                                marginSystemOption === obj?.id
                                              }
                                              disabled={
                                                formvalues?.tipType !==
                                                "winning"
                                              }
                                            />
                                            {obj?.MarginSystemvalue}
                                          </label>
                                        </FormControl>
                                      </li>
                                    ))}
                                </ul>
                              </Box>
                              <span className="text-danger">
                                {touched?.marginSystem && errors?.marginSystem
                                  ? errors?.marginSystem
                                  : ""}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      defaultExpanded
                      className="create-comp-accordion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography className="accordion-header-text">
                          Entry Fee and Prizes
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box className="comp-details jc-start">
                          <Typography className="textfield-text">
                            Entry Fee<span className="danger">*</span>:
                          </Typography>
                          <Box className="Filteritemlist-wrap">
                            <Box className="field-container">
                              <Box className="filter-list-racing">
                                <ul className="Filteritemlist-racing">
                                  {entryFee?.length > 0 &&
                                    entryFee?.map((obj, i) => (
                                      <li key={i}>
                                        <FormControl>
                                          <label>
                                            <Checkbox
                                              className="filter-racing"
                                              icon={<Unchecked />}
                                              checkedIcon={<Checked />}
                                              name="filter"
                                              onChange={() => {
                                                setEntryFeeOption(obj?.id);
                                                setEntryFeePoint("");
                                              }}
                                              checked={
                                                entryFeeOption === obj?.id
                                              }
                                            />
                                            {obj?.EntryFeeValue}
                                          </label>
                                        </FormControl>
                                      </li>
                                    ))}
                                </ul>
                              </Box>
                              <span className="text-danger">
                                {touched?.entryFee && errors?.entryFee
                                  ? errors?.entryFee
                                  : ""}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                        {entryFeeOption === 1 && (
                          <Box className="day-time-section">
                            <Box className="day-time-box yes-no-box-wrap">
                              <Typography className="cutoff-txt">
                                Select entry fee
                              </Typography>

                              <Box className="field-container inner-field-container">
                                <TextField
                                  id="outlined-basic"
                                  variant="outlined"
                                  className="comp-textfield"
                                  placeholder="Entry Fee"
                                  name="customPoints"
                                  type="number"
                                  onChange={(e) =>
                                    setEntryFeePoint(e?.target?.value)
                                  }
                                  value={entryFeePoint}
                                />
                                <span className="text-danger">
                                  {touched?.selectEntryFee &&
                                  errors?.selectEntryFee
                                    ? errors?.selectEntryFee
                                    : ""}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        )}
                        <Box className="comp-details jc-start comp-flex-start">
                          <Typography className="textfield-text">
                            Prize Information:
                          </Typography>
                          <Box className="Filteritemlist-wrap prize-Filteritemlist-wrap">
                            {/* <Box className="field-container">
                              <Box className="filter-list-racing">
                                <ul className="Filteritemlist-racing">
                                  {prizeInformation?.length > 0 &&
                                    prizeInformation?.map((obj, i) => (
                                      <li key={i}>
                                        <FormControl>
                                          <label>
                                            <Checkbox
                                              className="filter-racing"
                                              icon={<Unchecked />}
                                              checkedIcon={<Checked />}
                                              name="filter"
                                              onChange={() =>
                                                setPrizeInformationOption(
                                                  obj?.id,
                                                )
                                              }
                                              checked={
                                                prizeInformationOption ===
                                                obj?.id
                                              }
                                            />
                                            {obj?.PrizeInformationValue}
                                          </label>
                                        </FormControl>
                                      </li>
                                    ))}
                                </ul>
                              </Box>
                            </Box> */}
                            <Box className="day-time-section">
                              <Box className="field-container">
                                <TextareaAutosize
                                  id="outlined-basic"
                                  variant="outlined"
                                  className="details-textarea-field"
                                  placeholder="Prize Information"
                                  name="compAbout"
                                  maxRows={3}
                                  minRows={2}
                                  onChange={(e) =>
                                    setPrizeInformationText(e?.target?.value)
                                  }
                                  value={prizeInformationText}
                                  // error={Boolean(touched?.compAbout && errors?.compAbout)}
                                  // helperText={touched?.compAbout ? errors?.compAbout : ""}
                                />
                              </Box>
                            </Box>
                            <Box className="day-time-section">
                              <Box className="day-time-box yes-no-box-wrap">
                                <Typography className="cutoff-txt">
                                  Manage Prizes
                                </Typography>

                                <Box className="Filteritemlist-wrap">
                                  <Box className="field-container">
                                    <Box className="filter-list-racing">
                                      <ul className="Filteritemlist-racing">
                                        {prizeInformation?.length > 0 &&
                                          prizeInformation?.map((obj, i) => (
                                            <li key={i}>
                                              <FormControl>
                                                <label>
                                                  <Checkbox
                                                    className="filter-racing"
                                                    icon={<Unchecked />}
                                                    checkedIcon={<Checked />}
                                                    name="filter"
                                                    onChange={() =>
                                                      setManagePrizeInformation(
                                                        obj?.id,
                                                      )
                                                    }
                                                    checked={
                                                      managePrizeInformation ===
                                                      obj?.id
                                                    }
                                                  />
                                                  {obj?.PrizeInformationValue}
                                                </label>
                                              </FormControl>
                                            </li>
                                          ))}
                                      </ul>
                                    </Box>
                                  </Box>
                                </Box>
                                {managePrizeInformation === 1 && (
                                  <Box className="prize-info-container">
                                    <Box className="prize-flex prize-flex-header">
                                      <Typography className="w-80 cutoff-txt">
                                        Position
                                      </Typography>
                                      <Typography className="cutoff-txt">
                                        Prize money
                                      </Typography>
                                    </Box>
                                    {prizes?.map((prize, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          gap: 2,
                                          mb: 2,
                                        }}
                                        className="prize-info-item"
                                      >
                                        <Box className="w-80">
                                          <Typography className="prize-position">
                                            {prize?.position}
                                          </Typography>
                                        </Box>
                                        <Box className="field-container prize-field-container">
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            className="comp-textfield"
                                            placeholder="Prize money"
                                            name="customPoints"
                                            type="number"
                                            onChange={(e) =>
                                              handlePrizeChange(
                                                index,
                                                e?.target?.value,
                                              )
                                            }
                                            value={prize?.prize}
                                          />
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            className="comp-textfield"
                                            placeholder="Prize label"
                                            name="customPoints"
                                            type="text"
                                            onChange={(e) =>
                                              handleLabelChange(
                                                index,
                                                e?.target?.value,
                                              )
                                            }
                                            value={prize?.label}
                                          />
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            className="comp-textfield"
                                            placeholder="Prize description"
                                            name="customPoints"
                                            type="text"
                                            onChange={(e) =>
                                              handleDescChange(
                                                index,
                                                e?.target?.value,
                                              )
                                            }
                                            value={prize?.description}
                                          />
                                        </Box>
                                        <IconButton
                                          color="error"
                                          onClick={() => removePosition(index)}
                                          disabled={prizes?.length === 1}
                                          style={{
                                            opacity:
                                              prizes?.length === 1 ? 0.6 : 1,
                                          }}
                                          className="delete-icon"
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    ))}
                                    <Box>
                                      <Typography
                                        sx={{ color: "red", fontSize: "14px" }}
                                      >
                                        Note for price discriptation: Need to
                                        add $ sign before amount and write text
                                        as 'prize'/'price'/'Prize'/'price' and
                                        then (Add your text). For example
                                        sentence must be start with e.g. $500
                                        prize (Add your text).
                                      </Typography>
                                    </Box>

                                    <Button
                                      variant="contained"
                                      startIcon={<AddIcon />}
                                      onClick={addPosition}
                                      className="add-position-btn"
                                      sx={{ marginBottom: 2 }}
                                    >
                                      Add position
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        {/* {prizeInformationOption === 1 && (
                          <>
                           above code day time
                          </>
                        )} */}
                      </AccordionDetails>
                    </Accordion>
                  </Box>

                  <Box className="comp-button">
                    <Box className="comp-btn">
                      <Button
                        variant="contained"
                        className="btn-save"
                        type="submit"
                      >
                        {/* Create competition */}
                        {isLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Create competition"
                        )}
                      </Button>
                    </Box>
                  </Box>

                  {setformvalues(values)}
                </form>
              )}
            </Formik>
          </Box>
          <OurPartner />
        </Box>
        {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
      </Box>
      <SocialShareModal
        open={openSocialShareModal}
        handleClose={handleClose}
        title={""}
        closeIcon={true}
        shareCode={shareCode}
      />
      <UploadImageModal
        open={imageModalOpen}
        handleClose={handleImageClose}
        title={"Upload Comp Image"}
        closeIcon={false}
        getImage={getImage}
        //  listingFunction={() =>
        //    setTimeout(() => {
        //      getEventByID(stepperCount);
        //    }, 3000)
        //  }
      />
      <TippingSupportedTeamModal
        open={openSupportedTeamModal}
        handleClose={handleSupportedTeamModalClose}
        title={""}
        closeIcon={true}
        // handleJoin={handleAddComps}
        tournamentId={selectedOrg}
        sportId={selectedSports}
        compId={createdCompId}
        compCode={shareCode}
        callBackFunc={() => {}}
      />
      <Dialog
        onClose={handlePaymentClose}
        aria-labelledby="customized-dialog-title"
        open={paymentModalOpen}
        className="payment-modal"
      >
        <DialogTitle className="modal-title">
          <Typography variant="h6" className="title">
            Premium Comp Payment
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => handlePaymentClose()}
            className="close-icon"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="payment-details">
          <PaymentModal
            paymentModalOpen={paymentModalOpen}
            setPaymentModalOpen={setPaymentModalOpen}
            handleClose={handlePaymentClose}
            // fetchGetPlanDetails={fetchGetPlanDetails}
            planPrice={compFee}
            // planId={planId}
            // planDetails={planDetails}
            compId={createdCompId}
            handleSupportedTeamModal={handleSupportedTeamModal}
            handlePaymentStatus={handlePaymentStatus}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateCompPage;
