import { createColumnHelper } from "@tanstack/react-table";
import { Box, Typography } from "@mui/material";
import { Config } from "src/helpers/context";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import SortIcons from "src/components/Common/Icons/SortIcons";

const columnHelper = createColumnHelper();

export const columnCreator = (headers) => {
  return [
    columnHelper.accessor("rank", {
      header: ({ column }) => (
        <Box
          className="sorting-cell"
          onClick={column.getToggleSortingHandler()}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            # <SortIcons isSorted={column.getIsSorted()} />
          </Box>
        </Box>
      ),
      cell: ({ row }) => (
        <Typography sx={{ textAlign: "center" }}>{row.index + 1}</Typography>
      ),
      enableSorting: true,
      sortingFn: (rowA, rowB) => rowA.index - rowB.index,
      enableHiding: false,
    }),
    ...headers.map(({ key, value }) =>
      columnHelper.accessor(key, {
        header: ({ column }) => (
          <Box
            className="sorting-cell"
            onClick={column.getToggleSortingHandler()}
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent:
                key === "name" || key === "teamName" ? "start" : "center",
              alignItems: "center",
            }}
          >
            {value} <SortIcons isSorted={column.getIsSorted()} />
          </Box>
        ),
        enableSorting: true,
        cell: ({ row }) => {
          const item = row.original;
          if (key === "name") {
            return (
              <Box className="team-wrap">
                <Box className="team-image-wrap">
                  <img
                    src={
                      item?.image
                        ? item?.image?.includes("uploads")
                          ? Config.mediaURL + item?.image
                          : item?.image
                        : DefaultTeam
                    }
                    onError={(e) => {
                      e.target.src = DefaultTeam;
                    }}
                    alt="Player"
                    className="team-image"
                  />
                </Box>
                <Box className="team-name-wrap">
                  <Typography className="team-name">{item?.name}</Typography>
                </Box>
              </Box>
            );
          } else if (key === "teamName") {
            return (
              <Box className="team-wrap">
                <Box className="team-image-wrap">
                  <img
                    src={
                      item?.flag
                        ? item?.flag?.includes("uploads")
                          ? Config.mediaURL + item?.flag
                          : item?.flag
                        : DefaultTeam
                    }
                    onError={(e) => {
                      e.target.src = DefaultTeam;
                    }}
                    alt="Team"
                    className="team-image"
                  />
                </Box>
                <Box className="team-name-wrap">
                  <Typography className="team-name">
                    {item?.teamName}
                  </Typography>
                </Box>
              </Box>
            );
          } else if (
            key === "battingAverage" ||
            key === "strikeRate" ||
            key === "totalOv" ||
            key === "totalBalls"
          ) {
            return (
              <Typography sx={{ textAlign: "center" }}>
                {item?.[key]?.toFixed(2) ?? "-"}
              </Typography>
            );
          } else if (key === "form") {
            return (
              <Box className="form-cell-details">
                <Box className="win-loss-status">
                  {item?.[key]?.map((item, index) => {
                    return (
                      <span
                        style={{
                          background: item === "W" ? "#1C9A6C" : "#D84727",
                        }}
                      >
                        {item ?? "-"}
                      </span>
                    );
                  })}
                </Box>
              </Box>
            );

            // return (
            //   <Box
            //     sx={{
            //       display: "block",
            //       width: 26,
            //       height: 23,
            //       borderRadius: 3,
            //       fontSize: 14,
            //       lineHeight: "23px",
            //       fontWeight: 500,
            //       letterSpacing: 0,
            //       // color: "#ffffff",
            //     }}
            //   >
            //     {/* {item?.[key] ?? "-"} */}
            //     hello
            //   </Box>
            // );
          } else {
            return (
              <Typography sx={{ textAlign: "center" }}>
                {item?.[key] ?? "-"}
              </Typography>
            );
          }
        },
      }),
    ),
  ];
};
