import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as RightArrow } from "../../../../../../assets/images/icons/rightArrowThick.svg";
import NewsComp from "src/views/component/NewsComponent/NewsComp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useLocation, useNavigate, useParams } from "react-router";
import "../../../../NewsComponent/newsComp.scss";
import "./landingNews.scss";
import Loader from "src/components/Loader";
import { getSportsTitle } from "../../../teamCommonComponent/newSportsUtils";

const LandingNews = ({ selectedTeamName }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);

  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const urlTouranamentName = decodeURIComponent(
    searchParamsMenu.get("touranamentName"),
  );

  useEffect(() => {
    const tags = [urlTouranamentName, selectedTeamName]
      .filter(Boolean)
      .join(",");
    if (tags) {
      featchNewsList({ tag: tags });
    }
  }, [selectedTeamName, urlTouranamentName]);

  const featchNewsList = async ({ tag }) => {
    setLoading(true);
    const passApi = `/v2/news/articles?NewsCategoryId=&limit=6&offset=0&SportId=${params?.sportsId} ${tag ? `&tag=${tag}` : ""}`;
    const { status, data } = await axiosInstance.get(passApi);
    try {
      if (status === 200) {
        setNewsData(data?.result?.raw);
        //   setSelectOption(leagueData);
        //   setSelectedValue(leagueData?.[0]?.value);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleNavigateNews = () => {
    // Navigate to news page
    navigate(`/smartinfo/news/0`);
  };
  return (
    <>
      {loading ? (
        <div className="allsport-loader-center news-loader">
          <Loader />
        </div>
      ) : (
        newsData?.length > 0 && (
          <Box className="news-section landing-news-section">
            <Box className="all-news category-news" id="news-section">
              <Box className="landing-header">
                <Typography
                  variant="h3"
                  className="component-header hander-text"
                >
                  Latest {getSportsTitle(params?.sportsTypeName)} News
                </Typography>
                <Typography
                  className="view-all-text"
                  onClick={() => {
                    handleNavigateNews();
                  }}
                >
                  View All <RightArrow />
                </Typography>
              </Box>
              <NewsComp newsData={newsData} landing={true} />
            </Box>
          </Box>
        )
      )}
    </>
  );
};

export default LandingNews;
