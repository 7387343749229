import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import HomeLanding from "./homeLanding";
import NewsLanding from "./newsLanding";
import FixtureAndResultLanding from "./fixtureAndResultLanding";
import OddsFlucsLanding from "./oddsFlucsLanding";
import RankingsLanding from "./rankingsLanding";
import StatsLanding from "./statsLanding";
import "./newTeamSportPage.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { fetchViewExpertTipsCount } from "src/helpers/store/Actions/viewExpertTipsCount";
import { useDispatch } from "react-redux";
import RugbyLeaguePastData from "./pastData/NRL";
// import TeamSportsLayoutsLanding from "./teamSportLayoutLading";

const RendererView = ({ menuValue }) => {
  switch (menuValue) {
    case "home":
      return (
        <Box>
          <HomeLanding />
        </Box>
      );
    case "news":
      return (
        <Box>
          <NewsLanding />
        </Box>
      );
    case "fixtures_results":
      return (
        <Box>
          <FixtureAndResultLanding />
        </Box>
      );
    case "odds_flucs":
      return (
        <Box>
          {/* <OddsFlucsLanding /> */}
          <FixtureAndResultLanding />
        </Box>
      );
    case "rankings":
      return (
        <Box>
          <RankingsLanding />
        </Box>
      );
    case "stats":
      return (
        <Box>
          <StatsLanding />
        </Box>
      );

    case "ladder":
      return (
        <Box>
          <StatsLanding />
        </Box>
      );

    case "past_data":
      return <RugbyLeaguePastData />;

    default:
      return <Box>Page not found</Box>;
  }
};

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const NewTeamCricketSportsComponentPage = () => {
  const params = useParams();
  let teamSportType = params?.sportsTypeName;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const menuValue = searchParams.get("menu"); // Extracts the value of "menu"
  const dispatch = useDispatch();

  useEffect(() => {
    let sportId =
      teamSportType === "cricket"
        ? 4
        : teamSportType === "rugbyleague"
          ? 12
          : teamSportType === "australianrules"
            ? 9
            : null;
    if (sportId) {
      getAllTipData(sportId);
    }
  }, [teamSportType]);

  const getAllTipData = async (sId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/expertTips/web/getAllTips/count?SportId=${sId}&timezone=${timezone}`,
      );
      if (status === 200) {
        dispatch(fetchViewExpertTipsCount(data?.count));
      } else {
      }
    } catch (err) {}
  };

  return (
    <>
      <Box className="new-team-sports-component-wrap">
        <RendererView menuValue={menuValue} />
      </Box>
    </>
  );
};

export default NewTeamCricketSportsComponentPage;
