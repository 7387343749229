import useCloumn from "src/components/Common/Hooks/useColumn";
import { SPORTS_TYPE } from "../teamCommonComponent/newSportsUtils";
import PlayerTable from "./PlayerTable";
import { useLocation, useParams } from "react-router";
import { usePlayerStats } from "src/helpers/context/PlayersStats/PlayerStatsContext";
import { NRLLadderBody } from "src/assets/static";
import { useEffect, useState } from "react";

const StatsLandingTable = ({ selectedColumnStats }) => {
  const params = useParams();
  const sportsId = Number(params?.sportsId);
  const { playerStats, loading } = usePlayerStats() || {};
  const { cricketColumn, NRLLadderColum, NRLStatsColumn } = useCloumn();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const menuValue = searchParams.get("menu"); // Extracts the value of "menu"
  const [NRLColumn, setNRLColumn] = useState([]);

  useEffect(() => {
    if (menuValue === "ladder") {
      setNRLColumn(NRLLadderColum);
    } else {
      setNRLColumn(NRLStatsColumn);
    }
  }, [menuValue]);

  return (
    <>
      {/* Cricket */}
      {sportsId === SPORTS_TYPE.circket && (
        <PlayerTable
          tableData={playerStats?.body ?? []}
          loading={loading}
          columns={cricketColumn}
          selectedColumnStats={selectedColumnStats}
        />
      )}

      {/* Rugby League */}
      {sportsId === SPORTS_TYPE.rugbyLeague && (
        <PlayerTable
          tableData={NRLLadderBody ?? []}
          loading={loading}
          columns={NRLColumn}
          selectedColumnStats={selectedColumnStats}
          menuValue={menuValue}
        />
      )}

      {
        // Australian Rules
        sportsId === SPORTS_TYPE.australianrules && (
          <PlayerTable
            tableData={playerStats?.body ?? []}
            loading={loading}
            columns={cricketColumn}
          />
        )
      }
    </>
  );
};

export default StatsLandingTable;
