import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Box,
  Tabs,
  Tab,
  InputBase,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchIcon from "@mui/icons-material/Search";

const leagueCategories = [
  {
    category: "AUSTRALIA",
    leagues: [
      { name: "National Rugby League", id: "nrl" },
      { name: "NRLW - Women's Rugby League", id: "nrlw" },
      { name: "NRL Finals", id: "nrl-finals" },
      { name: "Queensland Cup", id: "qld-cup" },
      { name: "[League_name]", id: "league-1" },
    ],
  },
  {
    category: "INTERNATIONAL",
    leagues: [
      { name: "Super League", id: "super-league" },
      { name: "English Championship", id: "english-championship" },
      { name: "[League_name]", id: "int-league-1" },
      { name: "[League_name]", id: "int-league-2" },
      { name: "[League_name]", id: "int-league-3" },
      { name: "[League_name]", id: "int-league-4" },
    ],
  },
];

const dataCategories = [
  "Summary",
  "Coaches",
  "Finals",
  "Players",
  "Records",
  "Referees",
  "Seasons",
  "Tables",
  "Teams",
  "Venues",
  "Data",
];

const RugbyLeaguePastData = () => {
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState(["AUSTRALIA", "INTERNATIONAL"]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded
        ? [...expanded, panel]
        : expanded.filter((item) => item !== panel),
    );
  };

  const isExpanded = (panel) => expanded.includes(panel);

  return (
    <Box sx={{ width: "100%", margin: "0 auto" }}>
      {/* Navigation Tabs */}
      <Paper
        square
        elevation={0}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="LEAGUES" />
          <Tab label="TEAMS" />
          <Tab label="COACHES" />
          <Tab label="PLAYERS" />
          <Tab label="VENUES" />
        </Tabs>
      </Paper>

      {/* Search Bar */}
      <Box sx={{ p: 1, borderBottom: 1, borderColor: "divider" }}>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            boxShadow: "none",
            border: "1px solid #e0e0e0",
          }}
        >
          <SearchIcon sx={{ color: "action.active", ml: 1 }} />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search (by league name)"
            inputProps={{ "aria-label": "search rugby leagues" }}
          />
        </Paper>
      </Box>

      {/* League Accordions */}
      <Box className="edit-comp-details">
        {leagueCategories.map((category) => (
          <Accordion
            defaultExpanded
            className="create-comp-accordion"
            key={category.category}
            expanded={isExpanded(category.category)}
            onChange={handleAccordionChange(category.category)}
            sx={{
              backgroundColor: "#2c5aa0",
              color: "white",
              mb: 1, // Add margin-bottom to create space between accordions
              "&.Mui-expanded": {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls={`${category.category}-content`}
              id={`${category.category}-header`}
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                minHeight: 56,
                "&.Mui-expanded": {
                  minHeight: 56,
                },
              }}
            >
              <Typography
                sx={{ fontWeight: "bold" }}
                className="accordion-header-text"
              >
                {category.category}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, backgroundColor: "white" }}>
              <List disablePadding>
                {category.leagues.map((league) => (
                  <ListItem
                    key={league.id}
                    sx={{
                      borderBottom: "1px solid #f0f0f0",
                      paddingY: 0.5,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <DescriptionIcon sx={{ color: "#888" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={league.name}
                      sx={{
                        margin: 0,
                        "& .MuiTypography-root": {
                          fontWeight: "medium",
                          color: "#333",
                        },
                      }}
                    />
                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", gap: 1, ml: 2 }}
                    >
                      {dataCategories.map((dataCategory, index) => (
                        <Typography
                          key={`${league.id}-${dataCategory}`}
                          component="span"
                          sx={{
                            color: "#4455C7",
                            fontSize: "14px",
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                            padding: "10px",
                            paddingRight: "12px",
                            borderRight:
                              index < dataCategories.length - 1 &&
                              "1px solid #e0e0e0",
                          }}
                        >
                          {dataCategory}
                        </Typography>
                      ))}
                    </Box>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default RugbyLeaguePastData;
