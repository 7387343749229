import { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Chip,
  Button,
  Collapse,
  Drawer,
  ClickAwayListener,
  Checkbox,
} from "@mui/material";
import SettingsIcon from "../../../../components/Common/Icons/SettingsIcon";
import DoneArrowIcon from "src/components/Common/Icons/DoneArrowIcon";
import useScreen from "src/components/Common/Hooks/useScreen";
import CloseIcon from "@mui/icons-material/Close";
import CheckedIcon from "src/components/Common/Icons/CheckedIcon";
import InterMediateCheckedIcon from "src/components/Common/Icons/InterMediateCheckedIcon";
import DefaultCheckBoxIcon from "src/components/Common/Icons/DefaultCheckBoxIcon";

const CustomizeSection = ({
  categories,
  selectedStats,
  onToggleStat,
  handleToggleCategory,
  selectedCategories,
  selectedColumnStats,
}) => (
  <Box
    sx={{
      mb: 4,
      display: "grid",
      gap: 1,
      gridTemplateColumns: {
        xs: "1fr", // Mobile
        md: "1fr 1fr 1fr", // Desktop
      },
      fontFamily: "Inter",
    }}
  >
    {Object.entries(categories).map(([category, items]) => {
      const isCategoryPartiallySelected = (categoryItems) => {
        const selectedCount = categoryItems.filter((item) =>
          selectedStats.includes(item.value),
        ).length;
        return selectedCount > 0 && selectedCount < categoryItems.length;
      };
      return (
        <Box key={category} sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", mb: 1, fontSize: 12 }}
          >
            <Checkbox
              icon={<DefaultCheckBoxIcon />}
              checkedIcon={<CheckedIcon />}
              indeterminateIcon={<InterMediateCheckedIcon />}
              checked={selectedCategories.includes(category)}
              indeterminate={isCategoryPartiallySelected(items)}
              onChange={() => handleToggleCategory(category)}
            />
            {category}
          </Typography>
          <Box sx={{ display: "grid", gap: 1 }}>
            {items.map(({ label, value }) => (
              <Chip
                key={value}
                label={label}
                onClick={() => onToggleStat(value)}
                sx={{
                  backgroundColor: selectedStats.includes(value)
                    ? "#7FCFAD"
                    : "#f0f0f0",
                  color: selectedStats.includes(value) ? "white" : "black",
                  "&:hover": {
                    backgroundColor: selectedStats.includes(value)
                      ? "#6BB996"
                      : "#e0e0e0",
                  },
                  height: "40px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  "& .MuiChip-label": {
                    fontSize: "14px",
                    px: 2,
                  },
                  justifyContent: "left",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                icon={selectedStats.includes(value) ? <DoneArrowIcon /> : null}
              />
            ))}
          </Box>
        </Box>
      );
    })}
  </Box>
);

const StatsDashboard = ({
  setIsCustomizeOpen,
  isCustomizeOpen,
  categories,
  selectedStats,
  handleToggleStat,
  handleToggleCategory,
  selectedCategories,
}) => {
  const { width } = useScreen();
  const [open, setOpen] = useState(false);

  return (
    <Paper
      sx={{
        p: 1,
        display: "inline-block",
        margin: "auto",
        position: "relative",
        borderColor: "none",
        boxShadow: "none",
      }}
      className="customize-button"
    >
      <ClickAwayListener onClickAway={() => setIsCustomizeOpen(false)}>
        <Box sx={{ position: "relative" }}>
          <Button
            variant="outlined"
            startIcon={<SettingsIcon />}
            onClick={() => {
              width > 659
                ? setIsCustomizeOpen(!isCustomizeOpen)
                : setOpen(true);
            }}
            sx={{
              textTransform: "none",
              borderColor: "#D4D6D8",
              color: "#191919",
              backgroundColor: "#E7E9EC",
              fontSize: "16px",
              borderRadius: "8px",
            }}
          >
            {width > 659 ? "Customize" : "Filters"}
          </Button>

          <Collapse
            in={isCustomizeOpen}
            sx={{
              position: "absolute",
              top: "100%",
              right: "20%",
              zIndex: 199,
            }}
          >
            <Paper
              sx={{
                mt: 1,
                p: 2,
                boxShadow: 3,
                width: "max-content",
                minWidth: "100%",
                maxWidth: "1200px",
                maxHeight: "60vh",
                overflow: "auto",
              }}
            >
              <CustomizeSection
                categories={categories}
                selectedStats={selectedStats}
                onToggleStat={handleToggleStat}
                handleToggleCategory={handleToggleCategory}
                selectedCategories={selectedCategories}
              />
            </Paper>
          </Collapse>
        </Box>
      </ClickAwayListener>

      {width < 1023 ? (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={() => setOpen(false)}
          className="filter-drawer-section my-comp-filter-drawer"
        >
          <Box className="filter-header">
            <Typography>Filters</Typography>
            <CloseIcon onClick={() => setOpen(false)} />
          </Box>
          <Box className="filter-body">
            <CustomizeSection
              categories={categories}
              selectedStats={selectedStats}
              onToggleStat={handleToggleStat}
            />
          </Box>
          <Box className="filter-footer">
            <Box className="button-section">
              <Button
                className="filter-apply"
                // onClick={() => {
                //   filterApply();
                // }}
              >
                Apply
              </Button>
              <Box
                className="reset-all cursor-pointer"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Typography className="reset-all-text">
                  Clear filters
                </Typography>
              </Box>
            </Box>
          </Box>
        </Drawer>
      ) : (
        <></>
      )}
    </Paper>
  );
};

export default StatsDashboard;
